import crudApi from "@serie3/common/api/crudApi";
import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import endpoints from "@serie3/common/config/endpoints";
import { useTranslation } from "react-i18next";

const VerifyEmail = () => {
  const [registerStatus, setRegisterStatus] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const verifyData = async () => {
      const endpoint = endpoints.register.verify + window.location.search;
      const response = await crudApi.justGet(endpoint);
      setRegisterStatus(response.status);
    };
    verifyData();
  }, []);

  return (
    <Row className="justify-content-md-center">
      <br />
      <Col md={12} style={{ textAlign: "center" }}>
        <h3>
          {registerStatus ? (
            registerStatus === 200 ? (
              <span style={{ color: "white" }}>
                {t("Login.Register.Success")}
              </span>
            ) : (
              <span style={{ color: "red" }}>{t("Login.Register.Error")}</span>
            )
          ) : (
            t("Login.Register.Working")
          )}
        </h3>
      </Col>
    </Row>
  );
};

export default VerifyEmail;
