import createRequestValidationForm from "@serie3/common/Form/Register/RequestValidationForm";
import { useFormik } from "formik";
import { useRef, useState } from "react";
import Card from "../../Shared/Card";
import { useTranslation, Trans } from "react-i18next";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import TextField from "../../Shared/Form/TextField";

const RequestValidationLink = () => {
  const [showSuccess, setShowSuccess] = useState(false);
  const requestValidationForm = createRequestValidationForm(setShowSuccess);
  const formik = useFormik(requestValidationForm);

  const emailFieldRef = useRef(null);

  const { t } = useTranslation();

  return (
    <Card>
      <div className="text-center">
        <h1 className="h3">
          <Trans>Login.Request Verify Link.Title</Trans>
        </h1>
        <p>
          <Trans>Login.Request Verify Link.Subtitle</Trans>
        </p>
      </div>

        <TextField
          formik={formik}
          valueName={"email"}
          placeholder={t("Login.Request Verify Link.Placeholder")}
        />

        <div className="d-grid mt-5">
          <button
            className="btn btn-warning btn-lg"
            type="submit"
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            {t("Login.Request Verify Link.Button")}
          </button>
        </div>

      <div className="d-flex justify-content-between mt-4">
        <Link to={"/login"} className="btn-link text-decoration-none">
          {t("Login.Login")}
        </Link>
        <Link to={"/signup"} className="btn-link text-decoration-none">
          {t("Login.New Account")}
        </Link>
      </div>
      <div className="align-items-center text-center border-top pt-3 mt-3">
        {showSuccess ? (
          <span style={{ color: "green" }}>{t("Login.Messages.Success")} </span>
        ) : (
          ""
        )}
      </div>
    </Card>
  );
};

export default RequestValidationLink;
